import { Container } from "./styles";
import React, { FC } from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CaretRight from "../../assets/caret-right.svg";
import CaretLeft from "../../assets/caret-left.svg";

// Skills
import cssIcon from "../../assets/css-icon.svg";
import htmlIcon from "../../assets/html-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import Docker from "../../assets/docker.svg";
import Git from "../../assets/git-icon.svg";
import OpenAI from "../../assets/openai-icon.svg";
import Python from "../../assets/python-icon.svg";
import MySqlIcon from "../../assets/mysql-icon.svg";
import MongodbIcon from "../../assets/mongodb.svg";
import Redux from "../../assets/redux.svg";

interface ISkillCard {
  icon: any;
  name: string;
  description: string;
}

const skills: ISkillCard[] = [
  {
    icon: htmlIcon,
    name: "HTML",
    description: "Proficient in creating responsive and visually appealing web layouts using HTML and CSS.",
  },
  // {
  //   icon: cssIcon,
  //   name: "CSS/SCSS",
  //   description: "Proficient in creating responsive and visually appealing web layouts using HTML and CSS.",
  // },
  {
    icon: jsIcon,
    name: "JavaScript",
    description: "Skilled in using JavaScript to andd interactivity and dynamin behavior to web applications.",
  },
  {
    icon: typescriptIcon,
    name: "TypeScript",
    description: "Experienced in using TypeScript to develop robust web apps with static typing",
  },
  {
    icon: reactIcon,
    name: "ReactJS",
    description: "Experienced in building modern and efficient user interfaces using the React Library",
  },
  {
    icon: Redux,
    name: "Redux",
    description: "Familiar with managing application state using the Redux state management library.",
  },
  {
    icon: nodeIcon,
    name: "NodeJS",
    description: "Proficient in building server-side application and APIs using Node.js runtime.",
  },
  {
    icon: Python,
    name: "Python",
    description:
      "Experienced in building  applications and APIs using the Python language. scalable solutions with a keen emphasis on performance and reliability.",
  },

  {
    icon: Docker,
    name: "Docker",
    description:
      " Experienced in Docker to make, portable containers, optimizing deployments, ensuring efficiency, and maintaining reliability.",
  },
  {
    icon: Git,
    name: "Git",
    description: "Experienced in version control and collabaration using Git and GitHub",
  },
  {
    icon: OpenAI,
    name: "OpenAI",
    description:
      "OpenAI developer adept in crafting robust APIs, specializing in Python. Focus on performance, reliability, and scalability",
  },
  {
    icon: MySqlIcon,
    name: "MySql",
    description:
      "Experienced in MySQL adept in designing databases, optimizing queries, ensuring performance, and maintaining reliability.",
  },
  {
    icon: MongodbIcon,
    name: "MongoDB",
    description: "Skilled in working with MongoDB for creating and managing databases in web applications.",
  },
];

const SkillCard: FC<ISkillCard> = ({ icon, name, description }) => {
  return (
    <div className='skill-card-container'>
      <div className='skill-icon'>
        <img src={icon} alt={name} />
      </div>
      <h3 className='skill-name'>{name}</h3>
      <p className='skill-description'>{description}</p>

      <div className='skill-footer-line'></div>
    </div>
  );
};

const Skill: FC = () => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <img src={CaretRight} alt='Prev' />,
    prevArrow: <img src={CaretLeft} alt='Next' />,
    variableWidth: true,
    autoplay: true,
    rows: 1,
  };
  return (
    <Container id='skill'>
      <h2>
        <span>_</span>Skills
      </h2>
      <Slider {...settings}>
        {skills.map((skill, i) => {
          return <SkillCard {...skill} key={i} />;
        })}
      </Slider>
    </Container>
  );
};

export default Skill;
