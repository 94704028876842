import { Container } from "./styles";
import profileImage from "../../assets/about-img.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id='about'>
      <div className='about-text '>
        <ScrollAnimation animateIn='fadeInLeft'>
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInLeft' delay={0.2 * 1000}>
          <p>
            My name is <span style={{ color: "#5e60ce" }}>Shadab Alam</span>, I am Experienced Full Stack Developer with 2 years of
            expertise in creating scalable software solutions. Proficient in front-end and back-end development,
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInLeft' delay={0.4 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            I excel in various programming languages and frameworks. Skilled in database design, API development, I deliver dynamic and
            responsive web applications with a forward-thinking approach.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInLeft' delay={0.6 * 1000}>
          <p>
            I am a dedicated person who runs behind to realize their dreams, hardworking and results-oriented, always seek to achieve my
            best version.
          </p>
        </ScrollAnimation>
      </div>
      <div className='about-image'>
        <ScrollAnimation animateIn='fadeInRight' delay={0.6 * 1000}>
          <img src={profileImage} alt='Shadab Alam' />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
