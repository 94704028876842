import { Container } from "./styles";
import githubIcon from "../../assets/github-icon.svg";
import externalLinkIcon from "../../assets/external-link-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Portfolio() {
  return (
    <Container id='portfolio'>
      <h2>My portfolio</h2>

      <div className='projects'>
        <ScrollAnimation animateIn='flipInX'>
          <div className='project'>
            <header>
              <svg
                width='50'
                xmlns='http://www.w3.org/2000/svg'
                role='img'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#23ce6b '
                stroke-width='1'
                stroke-linecap='round'
                stroke-linejoin='round'>
                <title>Folder</title>
                <path d='M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z'></path>
              </svg>
              <div className='project-links'>
                <a href='https://github.com/heapland/heapland' target='_blank' rel='noreferrer'>
                  <img src={githubIcon} alt='GitHub' />
                </a>
              </div>
            </header>
            <div className='body'>
              <h3>Heapland</h3>
              <p>Lightweight interface for relational database, object storage and streaming data infrastructure</p>
              <p>Browse AWS S3 Bucket</p>
              <p>Query relational databases like - Postgresql, MySQL and MariaDB</p>
            </div>
            <footer>
              <ul className='tech-list'>
                <li>ReactJS</li>
                <li>TypeScript</li>
                <li>Scala</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='flipInX'>
          <div className='project'>
            <header>
              <svg
                width='50'
                xmlns='http://www.w3.org/2000/svg'
                role='img'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#23ce6b '
                stroke-width='1'
                stroke-linecap='round'
                stroke-linejoin='round'>
                <title>Folder</title>
                <path d='M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z'></path>
              </svg>
              <div className='project-links'>
                <a href='https://github.com/shadab19it/multilang-chat-app' target='_blank' rel='noreferrer'>
                  <img src={githubIcon} alt='GitHub' />
                </a>
              </div>
            </header>
            <div className='body'>
              <h3>Multi-Lang Chat App</h3>
              <p>
                The Multilang Chat App is a real-time chat application with robust features, designed for seamless communication across
                diverse languages.
              </p>
            </div>
            <footer>
              <ul className='tech-list'>
                <li>ReactJS</li>
                <li>NodeJS</li>
                <li>ExpressJS</li>
                <li>OpenAI</li>
                <li>Socket.io</li>
                <li>HarperDB</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='flipInX'>
          <div className='project'>
            <header>
              <svg
                width='50'
                xmlns='http://www.w3.org/2000/svg'
                role='img'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#23ce6b '
                stroke-width='1'
                stroke-linecap='round'
                stroke-linejoin='round'>
                <title>Folder</title>
                <path d='M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z'></path>
              </svg>
              <div className='project-links'>
                <a href='https://github.com/shadab19it/image-search-app' target='_blank' rel='noreferrer'>
                  <img src={githubIcon} alt='GitHub' />
                </a>
              </div>
            </header>
            <div className='body'>
              <h3>Image Search App</h3>
              <p>Search Images with image to images and text to images also download the images or share on whatsApp.</p>
            </div>
            <footer>
              <ul className='tech-list'>
                <li>ReactJS</li>
                <li>Python</li>
                <li>FastAPI</li>
                <li>DeepImageSearch</li>
                <li>Prisma</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='flipInX'>
          <div className='project'>
            <header>
              <svg
                width='50'
                xmlns='http://www.w3.org/2000/svg'
                role='img'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#23ce6b '
                stroke-width='1'
                stroke-linecap='round'
                stroke-linejoin='round'>
                <title>Folder</title>
                <path d='M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z'></path>
              </svg>
              <div className='project-links'>
                <a href='https://github.com/shadab19it/netflix-clone' target='_blank' rel='noreferrer'>
                  <img src={githubIcon} alt='GitHub' />
                </a>
                <a href='https://shadab19it.github.io/netflix-clone/' target='_blank' rel='noreferrer'>
                  <img src={externalLinkIcon} alt='Netflix clone' />
                </a>
              </div>
            </header>
            <div className='body'>
              <h3>Netflix Clone</h3>
              <p>
                This project is a Netflix clone built with ReactJS, showcasing a simplified version of the popular streaming platform's user
                interface.
              </p>
            </div>
            <footer>
              <ul className='tech-list'>
                <li>HTML</li>
                <li>CSS</li>
                <li>ReactJS</li>
                <li>Axios</li>
                <li>Open Source API</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
