import styled from "styled-components";

export const Container = styled.section`
  padding-top: 10%;
  h2 {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  h2 > span {
    color: var(--green);
  }
  .slick-slider {
    margin: 0 auto;
  }

  .slick-list {
    padding: 20px 0;
  }

  .slick-slide {
    width: 249px !important;
    margin-right: 15px;
  }

  .slick-arrow {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-arrow:hover {
    background: var(--green);
  }

  .slick-prev {
    top: -10%;
    left: 89.5%;
  }

  .slick-next {
    top: -10%;
    right: 2%;
  }

  .skill-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    height: 320px;
    coler: black;
    background-color: #2b2b2b;
    border-radius: 1.2rem;
    padding: 15px 20px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .skill-card-container:hover {
    transform: translateY(-20px);
    z-index: 100;
  }

  .skill-card-container .skill-icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }
  .skill-card-container .skill-name {
    margin: 8px 0 10px 0;
  }
  .skill-card-container .skill-description {
    font-size: 15px;
    color: #c5c5c5;
  }
  .skill-card-container .skill-footer-line {
    width: 100px;
    height: 5px;
    border-radius: 4px;
    background: var(--green);
    margin-top: 20px;
  }
  .skill-card-container .skill-icon img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .button {
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .hero-image {
    img {
      max-width: 500px;
    }
  }

  @media (max-width: 960px) {
    display: block;
    margin-top: 15%;
    .hero-text {
      h1 {
        font-size: 5rem;
      }
    }

    .hero-image {
      display: none;
    }
  }

  @media (max-width: 600px) {
    margin-top: 25%;
  }
  @media (max-width: 480px) {
    margin-top: 35%;

    .slick-prev {
      top: -2%;
      left: 65%;
    }

    .slick-next {
      top: -2%;
      right: 0%;
    }

    .slick-slide {
      margin-bottom: 15px;
    }
  }
`;
