import { useEffect } from "react";
import { Container } from "./styles";
import { useForm, ValidationError } from "@formspree/react";

export function Form() {
  const [state, handleSubmit, ResetFunction] = useForm("mkndwjdy");

  useEffect(() => {
    if (!state.submitting && state.succeeded) {
      ResetFunction();
      alert("Thanks I will contact you soon!");
    }
  }, [state.submitting]);

  return (
    <Container>
      <h2>Contact us from the below</h2>
      <form onSubmit={handleSubmit}>
        <input placeholder='Email' id='email' type='email' name='email' />
        <ValidationError prefix='Email' field='email' errors={state.errors} />
        <textarea placeholder='Leave your message' id='message' name='message' />
        <ValidationError prefix='Message' field='message' errors={state.errors} />
        <button type='submit' disabled={state.submitting}>
          Send
        </button>
      </form>
    </Container>
  );
}
