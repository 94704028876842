import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import { NavHashLink, HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import ShadabPortfolio from "../../assets/shadab-cv.pdf";
export function Header() {
  const [isActive, setActive] = useState(false);

  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  function closeMenu() {
    setActive(false);
  }

  return (
    <Container className='header-fixed'>
      <Router>
        <HashLink smooth to='#home' className='logo'>
          <span>Shadab</span>
          <span></span>
        </HashLink>

        <input onChange={toggleTheme} className='container_toggle' type='checkbox' id='switch' name='mode' />
        <label htmlFor='switch'>Toggle</label>

        <nav className={isActive ? "active" : ""}>
          <NavHashLink smooth to='#home' onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink smooth to='#about' onClick={closeMenu}>
            About Me
          </NavHashLink>
          <NavHashLink smooth to='#skill' onClick={closeMenu}>
            Skill
          </NavHashLink>
          <NavHashLink smooth to='#portfolio' onClick={closeMenu}>
            PORTFOLIO
          </NavHashLink>
          <NavHashLink smooth to='#contact' onClick={closeMenu}>
            Contact
          </NavHashLink>
          <a href={ShadabPortfolio} download className='button'>
            CV
          </a>
        </nav>

        <div
          aria-expanded={isActive ? "true" : "false"}
          aria-haspopup='true'
          aria-label={isActive ? "Fechar menu" : "Abrir menu"}
          className={isActive ? "menu active" : "menu"}
          onClick={() => {
            setActive(!isActive);
          }}></div>
      </Router>
    </Container>
  );
}
